import { LINKS_GLOBAL_WOWCHER } from '../links/links';
import { BRAND_WOWCHER } from '../setup/setup';

const DEFAULT_LINK =
  LINKS_GLOBAL_WOWCHER[process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod'][
    process.env.NEXT_PUBLIC_SITE || 'wowcher'
  ];

const BRAND =
  process.env.NEXT_PUBLIC_BRAND === BRAND_WOWCHER ? 'Wowcher' : 'LivingSocial';

export const LIGHTBOX_ABOVE_BUTTON = `By clicking GO TO DEALS!, LOGIN or Sign Up With Google you agree to receive daily emails 
featuring local service, shopping and travel offers from ${BRAND}.`;

export const LIGHTBOX_BELOW_BUTTON = `You can change your preferences or unsubscribe at any time by visiting our email preference centre or by clicking on the unsubscribe option, both of which are links in each of our emails. View our Privacy & Cookie Policy <a
href='${DEFAULT_LINK}/page/privacypolicy'
target="_blank"
rel="noreferrer noopener"
>
here</a>. Alternatively, press ESC or refresh the page to 
go directly to the site.`;

export const MODALS = {
  dealAvailability: 'deal-availability-modal',
  default: 'wowModal',
  lightbox: 'lightbox-modal',
};
