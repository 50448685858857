import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { MARKETING_CLAIMS_DEF_LINK } from '../../../config/links/links';
import { SITE_WOWCHERIE } from '../../../config/setup/setup';
import { getTimerObject } from '../../../helpers/timer';
import ThemeContext from '../../../providers/ThemeProvider';

export const TIMER_TYPES = {
  category: 'category',
  deal: 'deal',
};

export const TIMER_TEXT_ALIGN = {
  center: 'center',
  left: 'left',
  right: 'right',
};

export const HEADING_POSITION = {
  left: 'left',
  top: 'top',
};

// eslint-disable-next-line complexity
const TimerTo = ({
  heading,
  headingPosition = 'top',
  id,
  showUnits = true,
  type,
  unixTimestampTo,
  isMarketingClaim = false,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const theme = useContext(ThemeContext);
  const isWowcherIE = process.env.NEXT_PUBLIC_SITE === SITE_WOWCHERIE;
  const [remaining, setRemaining] = useState(getTimerObject(unixTimestampTo));
  const timerId = `${type}-timer-${id}`;

  let timerTextAlign = TIMER_TEXT_ALIGN.center;
  if (type === TIMER_TYPES.deal && headingPosition === HEADING_POSITION.left)
    timerTextAlign = TIMER_TEXT_ALIGN.right;

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(getTimerObject(unixTimestampTo));
    }, 1_000);

    return () => clearInterval(interval);
  }, []);

  if (!remaining) return null;

  const renderHeading = () => {
    return (
      <>
        {heading}
        {isMarketingClaim && !isWowcherIE && (
          <FontAwesomeIcon
            icon={faInfoCircle}
            onClick={() => {
              window.open(
                MARKETING_CLAIMS_DEF_LINK[process.env.NEXT_PUBLIC_SITE],
                '_blank',
              );
            }}
            style={{
              cursor: 'pointer',
              marginLeft: '0.2rem',
            }}
          />
        )}
      </>
    );
  };

  return (
    <>
      <div
        className={classNames('timer-container', {
          'timer-category': type === TIMER_TYPES.category,
        })}
      >
        {heading && headingPosition === HEADING_POSITION.top && (
          <p className="heading">{renderHeading()}</p>
        )}
        <div className="timer" data-testid="timer" id={timerId}>
          {heading && headingPosition === HEADING_POSITION.left && (
            <div className="item heading">{renderHeading()}</div>
          )}
          <div className="item">
            <div className="digit digit-days">{remaining.days}</div>
            {showUnits && <div className="unit">DAYS</div>}
          </div>
          <div className="item">
            <div className="digit digit-hours">{remaining.hours}</div>
            {showUnits && <div className="unit">HOURS</div>}
          </div>
          <div className="item">
            <div className="digit digit-minutes">{remaining.minutes}</div>
            {showUnits && <div className="unit">MINUTES</div>}
          </div>
          <div className="item">
            <div className="digit digit-seconds">{remaining.seconds}</div>
            {showUnits && <div className="unit">SECONDS</div>}
          </div>
        </div>
      </div>
      <style jsx>{`
        .timer-container {
          padding-left: 8px;
          text-align: center;
        }
        .timer-category {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          padding: 0.5rem 0 0 0;
        }
        .heading {
          font-size: 14px;
          color: ${type === TIMER_TYPES.category
            ? theme.colors.textonprimary
            : theme.commoncolors.black};
          font-weight: bold;
          line-height: ${headingPosition === HEADING_POSITION.left
            ? '36px'
            : '16px'};
          margin-bottom: 0;
          vertical-align: top;
        }
        .timer {
          padding: 0;
          margin: ${type === TIMER_TYPES.category
            ? '4px 0 0 0'
            : '6px -2px -2px 6px'};
          text-align: ${timerTextAlign};
        }
        .item {
          display: inline-block;
          margin: 2px;
          text-align: center;
        }
        .digit {
          background-color: ${type === TIMER_TYPES.deal
            ? theme.colors.primary
            : theme.commoncolors.white};
          border-radius: 4px;
          box-shadow: ${type === TIMER_TYPES.category
            ? `rgb(0, 0, 0, 0.1) 3px 3px 6px 0px inset,
               rgba(0, 0, 0, 0.05) -2px -2px 6px 1.25px inset`
            : theme.boxshadow};
          color: ${type === 'deal'
            ? theme.commoncolors.white
            : theme.colors.primary};
          display: inline-block;
          font-size: 16px;
          font-weight: bold;
          padding: 2px 4.75px;
        }
        .unit {
          color: ${type === TIMER_TYPES.deal
            ? theme.colors.primary
            : theme.commoncolors.white};
          font-size: 6.5px;
          line-height: 8px;
          text-align: center;
          margin-top: 4px;
        }

        @media only screen and (min-width: ${theme.breakpoints.mdUp}) {
          .digit {
            font-size: 18px;
            padding: 2px 6px;
          }
          .unit {
            font-size: 8px;
          }
          .timer-category {
            padding: 0;
            flex-direction: row;
            gap: 1rem;
          }
          .heading {
            font-size: ${type === TIMER_TYPES.category ? '16px' : '14px'};
          }
        }
      `}</style>
    </>
  );
};

TimerTo.propTypes = {
  heading: PropTypes.string,
  headingPosition: PropTypes.string,
  id: PropTypes.number,
  isMarketingClaim: PropTypes.bool,
  showUnits: PropTypes.bool,
  type: PropTypes.string,
  unixTimestampTo: PropTypes.string,
};

export default TimerTo;
