import { SEO_LOCATION_TEXT, SITE_NAME } from '../config/setup/setup';
import { WELLNESS_SEO_TEXT, WELLNESS_SEO_TITLE } from '../config/text/text';
import { getDefaultSeoText } from './facetedNavigation';
import { hyperLocalSeo } from './hyperLocalSeo';

export const getSeoText = (
  facetedNavigation,
  foundLocationName,
  foundLocationShortName,
  hasLocation,
  isHyperLocal,
  isLocationPage,
  isWellnessPage,
  locations,
  parsed,
  router,
  theme,
) => {
  let seoText = parsed?.defaultItem?.text || parsed?.text;
  let seoTitle =
    parsed?.defaultItem?.categoryName ?? (parsed?.defaultItem?.linkText || '');

  if (isLocationPage) {
    seoTitle = foundLocationName
      ? foundLocationName
      : locations.find(
          (item) => item.shortName.toLowerCase() === router.query.slug[0],
        )?.name ?? ' ';
    seoText = SEO_LOCATION_TEXT.replace(/##LOCATION##/gm, seoTitle).replace(
      /##SITE_NAME##/gm,
      SITE_NAME[process.env.NEXT_PUBLIC_SITE || 'wowcher'],
    );
  }

  if (isHyperLocal) {
    const { hyperLocalSeoTitle = '', hyperLocalSeoText = '' } = hyperLocalSeo(
      router,
      facetedNavigation,
    );

    if (hyperLocalSeoTitle && hyperLocalSeoText) {
      seoTitle = hyperLocalSeoTitle;
      seoText = hyperLocalSeoText;
    }
  }

  if (isWellnessPage) {
    seoText = WELLNESS_SEO_TEXT;
    seoTitle = WELLNESS_SEO_TITLE;
  }

  if (
    !isLocationPage &&
    !isHyperLocal &&
    !isWellnessPage &&
    hasLocation &&
    foundLocationName
  ) {
    seoTitle = seoTitle + ' in ' + foundLocationName;
  }

  if (!seoText || !seoText.length) {
    seoText = getDefaultSeoText(facetedNavigation);
  }

  if (parsed?.catSeoName) seoTitle = parsed?.catSeoName;

  return {
    seoText,
    seoTitle,
  };
};
