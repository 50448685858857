/* eslint-disable max-params */
import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import { ALL_CATEGORY_FILTERS } from '../config/constants/mapview';
import { MAP_VIEW_URL } from '../config/links/links';
import {
  FILTER_CATEGORY_MAPS,
  FILTER_MAXPRICE,
  FILTER_MINPRICE,
} from '../config/setup/setup';
import ErrorTrackingService from './errorTrackingService';
import httpCommonHeaders from './httpCommonHeaders';
// import mapViewJson from '../__tests__/__fixtures__/mapViewLondon.json';

/**
 * Call API to get map pins
 *
 * @returns {string} url
 * @param {object} url
 * @param {{lat: string, lon: string, bottomRightLat: string, bottomRightLon: string, topLeftLat: string, topLeftLon: string}} obj map area info
 */
export const addSearchParameters = (
  url,
  {
    categories,
    lat,
    lon,
    bottomRightLat,
    bottomRightLon,
    filterPrice,
    sliderValues,
    topLeftLat,
    topLeftLon,
    pageSize,
    stacked,
  },
) => {
  if (pageSize) url.searchParams.append('pageSize', pageSize);

  if (bottomRightLat && bottomRightLon && topLeftLat && topLeftLon) {
    url.searchParams.append('bottomRightLat', bottomRightLat);
    url.searchParams.append('bottomRightLon', bottomRightLon);
    url.searchParams.append('topLeftLat', topLeftLat);
    url.searchParams.append('topLeftLon', topLeftLon);
  } else if (lat && lon) {
    url.searchParams.append('lat', lat);
    url.searchParams.append('lon', lon);
  }

  if (categories) {
    categories.forEach((category) => {
      if (category) url.searchParams.append(FILTER_CATEGORY_MAPS, category);
    });
  }

  if (filterPrice && sliderValues) {
    const [minIndex, maxIndex] = sliderValues;
    if (minIndex > 0) {
      url.searchParams.append(FILTER_MINPRICE, filterPrice[minIndex].value);
    }
    if (maxIndex < filterPrice.length - 1) {
      url.searchParams.append(FILTER_MAXPRICE, filterPrice[maxIndex].value);
    }
  }

  if (stacked) {
    url.searchParams.append('stacked', stacked);
  }

  return url;
};

/**
 * Get new api url
 *
 * @returns {string} url
 * @param {{categories: [], filterPrice: [], sliderValues: [], pageSize: string, lat: string, lon: string, bottomRightLat: string, bottomRightLon: string, topLeftLat: string, topLeftLon: string}} obj map area info
 */
export const getNewApiUrl = ({
  bottomRightLat,
  bottomRightLon,
  categories,
  filterPrice,
  lat,
  lon,
  pageSize,
  sliderValues,
  topLeftLat,
  topLeftLon,
  stacked,
}) => {
  let url = new URL(
    `/v1/${process.env.NEXT_PUBLIC_BRAND}/deal/mapview`,
    process.env.NEXT_PUBLIC_PUBLIC_API,
  );

  url = addSearchParameters(url, {
    bottomRightLat,
    bottomRightLon,
    categories,
    filterPrice,
    lat,
    lon,
    pageSize,
    sliderValues,
    stacked,
    topLeftLat,
    topLeftLon,
  });

  return url.toString();
};

/**
 * get new page url
 *
 * @returns {string} url
 * @param {{categories: [], filterPrice: [], sliderValues: [], pageSize: string, lat: string, lon: string, bottomRightLat: string, bottomRightLon: string, topLeftLat: string, topLeftLon: string}} obj map area info */
export const getNewMapRelativeUrl = ({
  categories,
  lat,
  lon,
  bottomRightLat,
  bottomRightLon,
  filterPrice,
  sliderValues,
  topLeftLat,
  topLeftLon,
  pageSize,
}) => {
  let url = new URL(MAP_VIEW_URL, window.location.origin);

  url = addSearchParameters(url, {
    bottomRightLat,
    bottomRightLon,
    categories,
    filterPrice,
    lat,
    lon,
    pageSize,
    sliderValues,
    topLeftLat,
    topLeftLon,
  });

  const { pathname, search } = url;

  return `${pathname}${search}`;
};

/**
 * Call API to get map pins
 *
 * @param {{categories: [], filterPrice: [], sliderValues: [], pageSize: string, lat: string, lon: string, bottomRightLat: string, bottomRightLon: string, topLeftLat: string, topLeftLon: string}} obj map area info
 * @returns {[]}
 */
export const getPins = async ({
  bottomRightLat,
  bottomRightLon,
  categories,
  filterPrice,
  lat,
  lon,
  pageSize,
  sliderValues,
  topLeftLat,
  topLeftLon,
  stacked,
}) => {
  let response = [];

  const url = getNewApiUrl({
    bottomRightLat,
    bottomRightLon,
    categories,
    filterPrice,
    lat,
    lon,
    pageSize,
    sliderValues,
    stacked,
    topLeftLat,
    topLeftLon,
  });

  try {
    const result = await axios(url, {
      cache: false,
      headers: httpCommonHeaders(),
      method: 'GET',
      withCredentials: true,
    });
    if (result.status === 200 && result?.data) {
      response = result.data;
    } else {
      response = -1;
    }
  } catch (error) {
    response = -1;
    ErrorTrackingService.logError(error);
  }

  return response;
};

/**
 * add selected category to filters if not in the list
 *
 * @param apiFilters []
 * @param selectedCategories []
 * @returns {[]}
 */
export const addNonResponseFilters = (
  apiFilters = [],
  selectedCategories = [],
) => {
  if (!apiFilters) return [];

  const updatedFilters = apiFilters.slice();

  if (!selectedCategories) return updatedFilters;

  selectedCategories.forEach((selectedCategory) => {
    const foundInApiFilters = apiFilters.some(
      (filter) => filter.shortName === selectedCategory,
    );
    // a selected category missing from filters
    if (!foundInApiFilters) {
      const foundInAllFilters = ALL_CATEGORY_FILTERS.find(
        (filter) => filter.shortName === selectedCategory,
      );
      // add to current filters
      if (foundInAllFilters) {
        updatedFilters.push(foundInAllFilters);
      }
    }
  });

  return updatedFilters;
};

/**
 * Call API to fetch the deals for the carousel.
 *
 * @param item
 * @returns {[]}
 */
export const fetchCarouselDeals = async (item, stacked = false) => {
  const response = await getPins({
    lat: item.lat,
    lon: item.lon,
    stacked,
  });

  return response?.mapResults?.map((pin) => {
    return {
      ...pin,
      id: pin.dealIid,
      images: [pin.image],
      urlPath: pin.urlPath,
    };
  });
};

/**
 * Is path a map category
 *
 * @param category
 * @returns {string}
 */
export const getMapCategories = (category) => {
  const fullUK =
    'bottomRightLat=49.639&bottomRightLon=1.795&topLeftLat=58.228&topLeftLon=-6.444';
  switch (category) {
    case 'activities-entertainment':
      return 'catpage=true&categories=activities&categories=entertainment';
    case 'beauty':
      return 'catpage=true&categories=beauty';
    case 'health-fitness':
      return 'catpage=true&categories=healthcare';
    case 'restaurants-food':
      return 'catpage=true&categories=restaurantandfood';
    case 'uk-city-breaks':
      return `catpage=true&categories=ukcitybreaks&${fullUK}`;
    case 'uk-seaside':
      return `catpage=true&categories=ukseaside&${fullUK}`;
    case 'spas-country-house':
      return `catpage=true&categories=spascountryhouse&${fullUK}`;
    default:
      return '';
  }
};
